<template lang="pug">
.container.min-h-screen
  baseHeading Billing
  .grid.grid-cols-4.gap-4
    baseSelect(
      :options="optionsMonths",
      v-on:setOption="changeMonth",
      :optionSelected="monthSelected"
    )
    baseSelect(
      :options="optionsYears",
      v-on:setOption="changeYear",
      :optionSelected="yearSelected"
    )
    baseButton(size="normal", @click="getBilling") Search
    baseButton(size="normal", color="green", @click="downloadTableCsv") Download Excel

  .my-8
    .text-2xl.font-bold Total:
    .flex.justify-between.mt-4
      .group
        .name.text-gray-400.font-semibold payments
        .value {{ totalBilling.payments }}
        .h-px.bg-gray-500.w-full
        .name.text-gray-400.font-semibold Amount
        .value {{ setPrice(totalBilling.amount) }}
      .group
        .name.text-gray-400.font-semibold Chargerbacks
        .value {{ totalBilling.chargerbacks }}
        .h-px.bg-gray-500.w-full
        .name.text-gray-400.font-semibold Refunds
        .value {{ totalBilling.refunds }}
      .group
        .name.text-gray-400.font-semibold Chargerbacks Amount
        .value {{ setPrice(totalBilling.chargerbacks_amount) }}
        .h-px.bg-gray-500.w-full
        .name.text-gray-400.font-semibold Refunds Amount
        .value {{ setPrice(totalBilling.refunds_amount) }}
      .group
        .name.text-gray-400.font-semibold Taxes
        .value {{ setPrice(totalBilling.taxes) }}
        .h-px.bg-gray-500.w-full
        .name.text-gray-400.font-semibold Amount_real
        .value {{ setPrice(totalBilling.amount_real - totalBilling.taxes) }}

  dataTable.mt-4(
    :rows="billingFormated",
    :headings="headings",
    :options="options",
    ref="tableRef"
  )
</template>
<script>
import { computed, ref } from "vue";
import dataTable from "@/components/dataTables/dataTable";

import BillingRepository from "@/api/billing.repository";
import * as countriesName from "i18n-iso-countries";
export default {
  name: "Billing",
  components: {
    dataTable,
  },
  data: () => ({
    headings: [
      "Country",
      "Payments",
      "Amount",
      "CB",
      "Amount -CB",
      "Refunds",
      "Amount Refunds",
      "VAT",
      "Taxes",
      "Real amount",
    ],
    options: {
      perPage: 1000,
      perPageSelect: false,
      fixedHeight: false,
      footer: true,
      searchable: false,
    },
    optionsYears: [
      {
        name: "2021",
        id: 2021,
      },
      {
        name: "2022",
        id: 2022,
      },
      {
        name: "2023",
        id: 2023,
      },
      {
        name: "2024",
        id: 2024,
      },
      {
        name: "2025",
        id: 2025,
      },
    ],
    optionsMonths: [
      {
        name: "January",
        id: 0,
      },
      {
        name: "February",
        id: 1,
      },
      {
        name: "March",
        id: 2,
      },
      {
        name: "April",
        id: 3,
      },
      {
        name: "May",
        id: 4,
      },
      {
        name: "June",
        id: 5,
      },
      {
        name: "July",
        id: 6,
      },
      {
        name: "August",
        id: 7,
      },
      {
        name: "September",
        id: 8,
      },
      {
        name: "October",
        id: 9,
      },
      {
        name: "November",
        id: 10,
      },
      {
        name: "December",
        id: 11,
      },
    ],
  }),
  setup() {
    countriesName.registerLocale(require("i18n-iso-countries/langs/en.json"));
    const tableRef = ref(null);
    const actualMonth = new Date().getMonth();
    const actualYear = new Date().getFullYear();
    const monthSelected = ref(actualMonth);
    const yearSelected = ref(actualYear);
    const billingData = ref([]);

    const changeMonth = (monthId) => (monthSelected.value = monthId);
    const changeYear = (yearId) => (yearSelected.value = yearId);

    const getBilling = async () => {
      const { data } = await BillingRepository.getBilling(
        monthSelected.value,
        yearSelected.value
      );
      billingData.value = data;
    };

    const billingFormated = computed(() => {
      return billingData.value.map((b) => {
        return {
          c: `(${b.country}) ${countriesName.getName(b.country, "en", {
            select: "official",
          })}`,
          payments: `${b.payments}`,
          amount: setPrice(b.amount),
          chargerbacks: `${b.chargerbacks}`,
          chargerbacks_amount: setPrice(b.chargerbacksAmount),
          refunds: `${b.refunds}`,
          refunds_amount: setPrice(b.refundsAmount),
          vat: `${b.vatApplied}`,
          taxes: setPrice(b.taxes),
          amount_real: setPrice(b.amount_real),
        };
      });
    });

    const totalBilling = computed(() => {
      return billingData.value.reduce(
        (previous, current) => {
          previous.payments += current.payments;
          previous.amount += current.amount;
          previous.chargerbacks += current.chargerbacks;
          previous.chargerbacks_amount += current.chargerbacksAmount;
          previous.refunds += current.refunds;
          previous.refunds_amount += current.refundsAmount;
          previous.taxes += current.taxes;
          previous.amount_real += current.amount_real;
          return previous;
        },
        {
          payments: 0,
          amount: 0,
          chargerbacks: 0,
          chargerbacks_amount: 0,
          refunds: 0,
          refunds_amount: 0,
          taxes: 0,
          amount_real: 0,
        }
      );
    });

    getBilling();

    const setPrice = (price) => new Intl.NumberFormat("es-ES").format(price);

    const downloadTableCsv = () => {
      tableRef.value.exportDataTable();
    };

    return {
      tableRef,
      monthSelected,
      yearSelected,
      changeMonth,
      changeYear,
      getBilling,
      billingFormated,
      totalBilling,
      setPrice,
      downloadTableCsv,
    };
  },
};
</script>
<style lang="scss" scoped>
</style>