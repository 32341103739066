import { ApiAxios } from './api.config';

const RESOURCE = '/__admin/billing';

export default {
  async getBilling(month, year) {
    return await ApiAxios().get(RESOURCE, {
      params: {
        month,
        year
      }
    });
  }
};
